<template>
    <div id="contact" class="page">
        <h1 class="page-title">Contact</h1>
        <div class="contact-link-group">
            <div class="contact-link">
                <h3>Email</h3>
                <p>hello@carlosgamez.dev</p>
            </div>
            <div class="contact-link">
                <h3>Instagram</h3>
                <p>
                    <a href="https://www.instagram.com/empty_fox_skull/">@Empty_Fox_Skull</a>
                </p>
            </div>
            <div class="contact-link">
                <h3>Dribble</h3>
                <p>
                    <a href="https://dribbble.com/carlosgarts">Carlos Gamez</a>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Contact",
    mounted () {
        window.scrollTo(0, 0)
    }
}
</script>
<style lang="scss">
@import '../assets/scss/utils.scss';

#contact {
    text-align: center;
    @media (min-width: $brk-screen) {
        text-align: left;
    }
    .contact-link-group {
        .contact-link {
            margin: 52px 0;
            h3 {
                @include content-sub-title;
                margin: 11px 0;
            }
            p {
                @include content-title(1);
                margin: 11px 0;
            }
        }
    }
}
    
</style>
